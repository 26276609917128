enum LogTrackErrorEventType {
  ID_PAGE_WRONG_FORMAT_DETAIL_ID = 'id-page-wrong-format-detail-id',
  ID_PAGE_FETCH_DETAIL = 'id-page-fetch-detail',
  ID_PAGE_PROLONG_DETAIL = 'id-page-prolong-detail',
  ERROR_PAGE_SHOW = 'error-page-show',
}

enum LogTrackInfoEventType {
  INDEX_PAGE_SHOW = 'index-page-show',
  ID_PAGE_SHOW = 'id-page-show',
}

export { LogTrackErrorEventType, LogTrackInfoEventType }
