import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { Status } from '~common/enums/status.ts'

let appInsights: ApplicationInsights | null = null
const initAppInsights = () => {
  if (!appInsights) {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: 'c2fd85ef-f328-4c49-ac8c-ebaf5618f1d8',
        disableFetchTracking: true,
        disableAjaxTracking: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        disableCookiesUsage: false, // todo napojit na cookie consent
      },
    })
    appInsights.loadAppInsights()

    // Optional: Set up custom telemetry properties (e.g., user session or app version)
    // appInsights.addTelemetryInitializer((envelope) => {
    //   envelope.tags['ai.cloud.role'] = 'frontend' // Example: Add a cloud role name
    // })
  }

  return appInsights
}

const cookieScript = (locale: string, apiKey: string) => ({
  src: 'https://consent.cookiefirst.com/banner.js',
  'data-cookiefirst-key': apiKey,
  defer: true,
  'data-language': locale,
})

const clarityScript = {
  innerHTML: `(function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "o5lx9pkgl5");`,
  type: 'text/javascript',
}

const errorTrackEvent = (name: string, properties?: { [key: string]: any }) => {
  if (!navigator) {
    return
  }

  const { userAgent, language } = navigator

  const trackData = {
    name,
    properties: {
      severity: Status.ERROR,
      navigator: { userAgent, language },
      errorDate: new Date(),
      location: window.location.toString(),
      ...properties,
    },
  }

  if (!window.appInsights) {
    console.error(`Error track event`, trackData)
    return
  }

  window.appInsights.trackEvent({ ...trackData })
}

const infoTrackEvent = (name: string, properties?: { [key: string]: any }) => {
  if (!navigator) {
    return
  }

  const { userAgent, language } = navigator

  const trackData = {
    name,
    properties: {
      severity: Status.INFO,
      navigator: { userAgent, language },
      date: new Date(),
      location: window.location.toString(),
      ...properties,
    },
  }

  if (!window.appInsights) {
    console.log(`Info track event`, trackData)
    return
  }

  window.appInsights.trackEvent({ ...trackData })
}

export { initAppInsights, cookieScript, clarityScript, errorTrackEvent, infoTrackEvent }
