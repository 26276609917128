import type { Locale } from '@intlify/core-base'
import beFlag from '@web/packeta-ui-styleguide/img/flags/belgium.svg'
import bgFlag from '@web/packeta-ui-styleguide/img/flags/bulgaria.svg'
import hrFlag from '@web/packeta-ui-styleguide/img/flags/croatia.svg'
import czFlag from '@web/packeta-ui-styleguide/img/flags/czechia.svg'
import daFlag from '@web/packeta-ui-styleguide/img/flags/denmark.svg'
import etFlag from '@web/packeta-ui-styleguide/img/flags/estonia.svg'
import fiFlag from '@web/packeta-ui-styleguide/img/flags/finland.svg'
import frFlag from '@web/packeta-ui-styleguide/img/flags/france.svg'
import deFlag from '@web/packeta-ui-styleguide/img/flags/germany.svg'
import elFlag from '@web/packeta-ui-styleguide/img/flags/greece.svg'
import huFlag from '@web/packeta-ui-styleguide/img/flags/hungary.svg'
import itFlag from '@web/packeta-ui-styleguide/img/flags/italy.svg'
import lvFlag from '@web/packeta-ui-styleguide/img/flags/latvia.svg'
import ltFlag from '@web/packeta-ui-styleguide/img/flags/lithuania.svg'
import plFlag from '@web/packeta-ui-styleguide/img/flags/poland.svg'
import ptFlag from '@web/packeta-ui-styleguide/img/flags/portugal.svg'
import roFlag from '@web/packeta-ui-styleguide/img/flags/romania.svg'
import ruFlag from '@web/packeta-ui-styleguide/img/flags/russia.svg'
import skFlag from '@web/packeta-ui-styleguide/img/flags/slovakia.svg'
import siFlag from '@web/packeta-ui-styleguide/img/flags/slovenia.svg'
import esFlag from '@web/packeta-ui-styleguide/img/flags/spain.svg'
import svFlag from '@web/packeta-ui-styleguide/img/flags/sweden.svg'
import ukFlag from '@web/packeta-ui-styleguide/img/flags/ukraine.svg'
import enFlag from '@web/packeta-ui-styleguide/img/flags/unitedKingdom.svg'
import type { Component } from 'vue'
import type { RouteLocation, RouteLocationRaw } from 'vue-router'
import { Locales } from '~common/enums'
import i18nConfig from '~config/i18nConfig.ts'

const langFlags: { [key in Locales]: Component } = {
  [Locales.CS]: czFlag,
  [Locales.EN]: enFlag,
  [Locales.DE]: deFlag,
  [Locales.HU]: huFlag,
  [Locales.SK]: skFlag,
  [Locales.PL]: plFlag,
  [Locales.RO]: roFlag,
  [Locales.UK]: ukFlag,
  [Locales.ES]: esFlag,
  [Locales.FR]: frFlag,
  [Locales.BE]: beFlag,
  [Locales.PT]: ptFlag,
  [Locales.RU]: ruFlag,
  [Locales.SV]: svFlag,
  [Locales.EL]: elFlag,
  [Locales.IT]: itFlag,
  [Locales.BG]: bgFlag,
  [Locales.SI]: siFlag,
  [Locales.HR]: hrFlag,
  [Locales.LV]: lvFlag,
  [Locales.LT]: ltFlag,
  [Locales.ET]: etFlag,
  [Locales.DA]: daFlag,
  [Locales.FI]: fiFlag,
}

const langNames: { [key in Locales]: string } = {
  [Locales.CS]: 'Čeština',
  [Locales.EN]: 'English',
  [Locales.DE]: 'Deutsch',
  [Locales.HU]: 'Magyar',
  [Locales.SK]: 'Slovenčina',
  [Locales.PL]: 'Polski',
  [Locales.RO]: 'Românesc',
  [Locales.UK]: 'Українська',
  [Locales.ES]: 'Español',
  [Locales.FR]: 'Français',
  [Locales.BE]: 'Français (BE)',
  [Locales.PT]: 'Português',
  [Locales.RU]: 'Русский',
  [Locales.SV]: 'Svenska',
  [Locales.EL]: 'Ελληνικά',
  [Locales.IT]: 'Italiano',
  [Locales.BG]: 'Български',
  [Locales.SI]: 'Slovenščina',
  [Locales.HR]: 'Hrvatski',
  [Locales.LV]: 'Latviešu',
  [Locales.LT]: 'Lietuvių',
  [Locales.ET]: 'Eesti keel',
  [Locales.DA]: 'Dansk',
  [Locales.FI]: 'Suomi',
}

const hreflangNames: { [key in Locales]: string } = {
  [Locales.CS]: 'cs-CZ',
  [Locales.EN]: 'en-GB',
  [Locales.DE]: 'de-DE',
  [Locales.HU]: 'hu-HU',
  [Locales.SK]: 'sk-SK',
  [Locales.PL]: 'pl-PL',
  [Locales.RO]: 'ro-RO',
  [Locales.UK]: 'uk-UA',
  [Locales.ES]: 'es-ES',
  [Locales.FR]: 'fr-FR',
  [Locales.BE]: 'fe-BE',
  [Locales.PT]: 'pt-PT',
  [Locales.RU]: 'ru-RU',
  [Locales.SV]: 'sv-SE',
  [Locales.EL]: 'el-GR',
  [Locales.IT]: 'it-IT',
  [Locales.BG]: 'bg-BG',
  [Locales.SI]: 'sl-SI',
  [Locales.HR]: 'hr-HR',
  [Locales.LV]: 'lv-LV',
  [Locales.LT]: 'lt-LT',
  [Locales.ET]: 'et-EE',
  [Locales.DA]: 'da-DK',
  [Locales.FI]: 'fi-FI',
}

const localeHreflangLinks = (
  webUrl: string,
  availableLocales: Locales[],
  route: RouteLocation | RouteLocationRaw,
  localePath: (route: RouteLocation | RouteLocationRaw, locale?: Locale) => string,
) => {
  const langLinks: any[] = [
    {
      hreflang: 'x-default',
      href: `${webUrl}${localePath(route, i18nConfig.defaultLocale)}`,
      rel: 'alternate',
    },
  ]
  availableLocales.forEach((locale) => {
    const localePrefix = `${i18nConfig.defaultLocale === locale ? `/${locale}` : ''}`
    const path = localePath(route, locale) === '/' ? '' : localePath(route, locale)
    langLinks.push({
      hreflang: hreflangNames[locale as Locales],
      href: `${webUrl}${localePrefix}${path}`,
      rel: 'alternate',
    })
    langLinks.push({
      hreflang: locale,
      href: `${webUrl}${localePrefix}${path}`,
      rel: 'alternate',
    })
  })

  return langLinks
}

export { langFlags, langNames, hreflangNames, localeHreflangLinks }
