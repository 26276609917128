enum WebUrl {
  LOCALHOST = 'http://localhost:3000',
  DEV = 'https://tracking-web-fe-tracking-web-dev.non-prod-swedencentral-1.packeta-com.codenow.com',
}

enum Environment {
  DEV = 'dev',
  PROD = 'prod',
}

export { WebUrl, Environment }
