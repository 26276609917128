import { defineNuxtPlugin } from 'nuxt/app'
import { Environments } from '~common/enums'
import { initAppInsights } from '~common/helpers/logging.ts'

export default defineNuxtPlugin(() => {
  if (useRuntimeConfig().public.environment !== Environments.PROD) {
    return
  }
  // Initialize and store on window
  window.appInsights = initAppInsights()

  // Optionally track initial page view
  window.appInsights.trackPageView()
})
