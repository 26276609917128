enum Emits {
  BLUR = 'blur',
  CLICK = 'click',
  CLOSE = 'close',
  COMPLETE = 'complete',
  ENTER = 'enter',
  FOCUS = 'focus',
  ERROR = 'error',
  INPUT = 'input',
  SELECT_OPTION = 'select:option',
  SUBMIT = 'submit',
}

enum ButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset',
}

enum InputModeType {
  TEL = 'tel',
  SEARCH = 'search',
  TEXT = 'text',
  EMAIL = 'email',
  URL = 'url',
  NONE = 'none',
  NUMERIC = 'numeric',
  DECIMAL = 'decimal',
}

enum Environments {
  DEV = 'dev',
  PROD = 'prod',
}

enum Viewports {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = 'xxl',
  '3XL' = '3xl',
}

export { Emits, ButtonType, InputModeType, Environments, Viewports }
