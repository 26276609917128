import { Locales } from '../common/enums/locale'

export const locales = [
  {
    code: Locales.EN,
    name: 'English',
    iso: 'en-GB',
    file: 'en-GB.json',
  },
  {
    code: Locales.CS,
    name: 'Czech',
    iso: 'cs-CZ',
    file: 'cs-CZ.json',
  },
  {
    code: Locales.DE,
    name: 'German',
    iso: 'de-DE',
    file: 'de-DE.json',
  },
  {
    code: Locales.HU,
    name: 'Hungarian',
    iso: 'hu-HU',
    file: 'hu-HU.json',
  },
  {
    code: Locales.SK,
    name: 'Slovak',
    iso: 'sk-SK',
    file: 'sk-SK.json',
  },
  {
    code: Locales.PL,
    name: 'Polish',
    iso: 'pl-PL',
    file: 'pl-PL.json',
  },
  {
    code: Locales.RO,
    name: 'Romanian',
    iso: 'ro-RO',
    file: 'ro-RO.json',
  },
  {
    code: Locales.UK,
    name: 'Ukrainian',
    iso: 'uk-UA',
    file: 'uk-UA.json',
  },
  {
    code: Locales.ES,
    name: 'Spanish',
    iso: 'es-ES',
    file: 'es-ES.json',
  },
  {
    code: Locales.FR,
    name: 'French',
    iso: 'fr-FR',
    file: 'fr-FR.json',
  },
  // {
  //   code: Locales.BE,
  //   name: "Belgian",
  //   iso: "nl-BE",
  //   file: "nl-BE.json",
  // },
  {
    code: Locales.PT,
    name: 'Portuguese',
    iso: 'pt-PT',
    file: 'pt-PT.json',
  },
  {
    code: Locales.RU,
    name: 'Russian',
    iso: 'ru-RU',
    file: 'ru-RU.json',
  },
  {
    code: Locales.SV,
    name: 'Swedish',
    iso: 'sv-SE',
    file: 'sv-SE.json',
  },
  {
    code: Locales.EL,
    name: 'Greek',
    iso: 'el-GR',
    file: 'el-GR.json',
  },
  {
    code: Locales.IT,
    name: 'Italian',
    iso: 'it-IT',
    file: 'it-IT.json',
  },
  {
    code: Locales.BG,
    name: 'Bulgarian',
    iso: 'bg-BG',
    file: 'bg-BG.json',
  },
  {
    code: Locales.SI,
    name: 'Slovenian',
    iso: 'sl-SI',
    file: 'sl-SI.json',
  },
  {
    code: Locales.HR,
    name: 'Croatian',
    iso: 'hr-HR',
    file: 'hr-HR.json',
  },
  {
    code: Locales.LV,
    name: 'Latvian',
    iso: 'lv-LV',
    file: 'lv-LV.json',
  },
  {
    code: Locales.LT,
    name: 'Lithuanian',
    iso: 'lt-LT',
    file: 'lt-LT.json',
  },
  {
    code: Locales.ET,
    name: 'Estonian',
    iso: 'et-EE',
    file: 'et-EE.json',
  },
  {
    code: Locales.DA,
    name: 'Danish',
    iso: 'da-DK',
    file: 'da-DK.json',
  },
  {
    code: Locales.FI,
    name: 'Finnish',
    iso: 'fi-FI',
    file: 'fi-FI.json',
  },
]

const i18nConfig = {
  locales,
  defaultLocale: 'en',
  strategy: 'prefix_and_default',
  langDir: 'locales/',
}

export default i18nConfig
