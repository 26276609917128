import { default as _91id_93fjwOitDkR3Meta } from "/workspace/source/pages/[id].vue?macro=true";
import { default as indexxQfHRAQhDJMeta } from "/workspace/source/pages/index.vue?macro=true";
import { default as component_45stubrcb3Zc6VWDMeta } from "/workspace/source/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubrcb3Zc6VWD } from "/workspace/source/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "id___en___default",
    path: "/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "id___en",
    path: "/en/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "id___cs",
    path: "/cs/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "id___de",
    path: "/de/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "id___hu",
    path: "/hu/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "id___sk",
    path: "/sk/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "id___pl",
    path: "/pl/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "id___ro",
    path: "/ro/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "id___uk",
    path: "/uk/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "id___es",
    path: "/es/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "id___fr",
    path: "/fr/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "id___pt",
    path: "/pt/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "id___ru",
    path: "/ru/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "id___sv",
    path: "/sv/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "id___el",
    path: "/el/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "id___it",
    path: "/it/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "id___bg",
    path: "/bg/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "id___si",
    path: "/si/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "id___hr",
    path: "/hr/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "id___lv",
    path: "/lv/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "id___lt",
    path: "/lt/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "id___et",
    path: "/et/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "id___da",
    path: "/da/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "id___fi",
    path: "/fi/:id()",
    component: () => import("/workspace/source/pages/[id].vue")
  },
  {
    name: "index___en___default",
    path: "/",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: "index___cs",
    path: "/cs",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: "index___hu",
    path: "/hu",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: "index___sk",
    path: "/sk",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: "index___pl",
    path: "/pl",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: "index___ro",
    path: "/ro",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: "index___uk",
    path: "/uk",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: "index___pt",
    path: "/pt",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: "index___ru",
    path: "/ru",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: "index___sv",
    path: "/sv",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: "index___el",
    path: "/el",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: "index___bg",
    path: "/bg",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: "index___si",
    path: "/si",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: "index___hr",
    path: "/hr",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: "index___lv",
    path: "/lv",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: "index___lt",
    path: "/lt",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: "index___et",
    path: "/et",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: "index___da",
    path: "/da",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: "index___fi",
    path: "/fi",
    component: () => import("/workspace/source/pages/index.vue")
  },
  {
    name: component_45stubrcb3Zc6VWDMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubrcb3Zc6VWD
  }
]