
// @ts-nocheck
import locale__workspace_source_locales_en_GB_json from "../locales/en-GB.json";
import locale__workspace_source_locales_cs_CZ_json from "../locales/cs-CZ.json";
import locale__workspace_source_locales_de_DE_json from "../locales/de-DE.json";
import locale__workspace_source_locales_hu_HU_json from "../locales/hu-HU.json";
import locale__workspace_source_locales_sk_SK_json from "../locales/sk-SK.json";
import locale__workspace_source_locales_pl_PL_json from "../locales/pl-PL.json";
import locale__workspace_source_locales_ro_RO_json from "../locales/ro-RO.json";
import locale__workspace_source_locales_uk_UA_json from "../locales/uk-UA.json";
import locale__workspace_source_locales_es_ES_json from "../locales/es-ES.json";
import locale__workspace_source_locales_fr_FR_json from "../locales/fr-FR.json";
import locale__workspace_source_locales_pt_PT_json from "../locales/pt-PT.json";
import locale__workspace_source_locales_ru_RU_json from "../locales/ru-RU.json";
import locale__workspace_source_locales_sv_SE_json from "../locales/sv-SE.json";
import locale__workspace_source_locales_el_GR_json from "../locales/el-GR.json";
import locale__workspace_source_locales_it_IT_json from "../locales/it-IT.json";
import locale__workspace_source_locales_bg_BG_json from "../locales/bg-BG.json";
import locale__workspace_source_locales_sl_SI_json from "../locales/sl-SI.json";
import locale__workspace_source_locales_hr_HR_json from "../locales/hr-HR.json";
import locale__workspace_source_locales_lv_LV_json from "../locales/lv-LV.json";
import locale__workspace_source_locales_lt_LT_json from "../locales/lt-LT.json";
import locale__workspace_source_locales_et_EE_json from "../locales/et-EE.json";
import locale__workspace_source_locales_da_DK_json from "../locales/da-DK.json";
import locale__workspace_source_locales_fi_FI_json from "../locales/fi-FI.json";


export const localeCodes =  [
  "en",
  "cs",
  "de",
  "hu",
  "sk",
  "pl",
  "ro",
  "uk",
  "es",
  "fr",
  "pt",
  "ru",
  "sv",
  "el",
  "it",
  "bg",
  "si",
  "hr",
  "lv",
  "lt",
  "et",
  "da",
  "fi"
]

export const localeLoaders = {
  "en": [{ key: "../locales/en-GB.json", load: () => Promise.resolve(locale__workspace_source_locales_en_GB_json), cache: true }],
  "cs": [{ key: "../locales/cs-CZ.json", load: () => Promise.resolve(locale__workspace_source_locales_cs_CZ_json), cache: true }],
  "de": [{ key: "../locales/de-DE.json", load: () => Promise.resolve(locale__workspace_source_locales_de_DE_json), cache: true }],
  "hu": [{ key: "../locales/hu-HU.json", load: () => Promise.resolve(locale__workspace_source_locales_hu_HU_json), cache: true }],
  "sk": [{ key: "../locales/sk-SK.json", load: () => Promise.resolve(locale__workspace_source_locales_sk_SK_json), cache: true }],
  "pl": [{ key: "../locales/pl-PL.json", load: () => Promise.resolve(locale__workspace_source_locales_pl_PL_json), cache: true }],
  "ro": [{ key: "../locales/ro-RO.json", load: () => Promise.resolve(locale__workspace_source_locales_ro_RO_json), cache: true }],
  "uk": [{ key: "../locales/uk-UA.json", load: () => Promise.resolve(locale__workspace_source_locales_uk_UA_json), cache: true }],
  "es": [{ key: "../locales/es-ES.json", load: () => Promise.resolve(locale__workspace_source_locales_es_ES_json), cache: true }],
  "fr": [{ key: "../locales/fr-FR.json", load: () => Promise.resolve(locale__workspace_source_locales_fr_FR_json), cache: true }],
  "pt": [{ key: "../locales/pt-PT.json", load: () => Promise.resolve(locale__workspace_source_locales_pt_PT_json), cache: true }],
  "ru": [{ key: "../locales/ru-RU.json", load: () => Promise.resolve(locale__workspace_source_locales_ru_RU_json), cache: true }],
  "sv": [{ key: "../locales/sv-SE.json", load: () => Promise.resolve(locale__workspace_source_locales_sv_SE_json), cache: true }],
  "el": [{ key: "../locales/el-GR.json", load: () => Promise.resolve(locale__workspace_source_locales_el_GR_json), cache: true }],
  "it": [{ key: "../locales/it-IT.json", load: () => Promise.resolve(locale__workspace_source_locales_it_IT_json), cache: true }],
  "bg": [{ key: "../locales/bg-BG.json", load: () => Promise.resolve(locale__workspace_source_locales_bg_BG_json), cache: true }],
  "si": [{ key: "../locales/sl-SI.json", load: () => Promise.resolve(locale__workspace_source_locales_sl_SI_json), cache: true }],
  "hr": [{ key: "../locales/hr-HR.json", load: () => Promise.resolve(locale__workspace_source_locales_hr_HR_json), cache: true }],
  "lv": [{ key: "../locales/lv-LV.json", load: () => Promise.resolve(locale__workspace_source_locales_lv_LV_json), cache: true }],
  "lt": [{ key: "../locales/lt-LT.json", load: () => Promise.resolve(locale__workspace_source_locales_lt_LT_json), cache: true }],
  "et": [{ key: "../locales/et-EE.json", load: () => Promise.resolve(locale__workspace_source_locales_et_EE_json), cache: true }],
  "da": [{ key: "../locales/da-DK.json", load: () => Promise.resolve(locale__workspace_source_locales_da_DK_json), cache: true }],
  "fi": [{ key: "../locales/fi-FI.json", load: () => Promise.resolve(locale__workspace_source_locales_fi_FI_json), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "language": "en-GB",
      "files": [
        "/workspace/source/locales/en-GB.json"
      ]
    },
    {
      "code": "cs",
      "name": "Czech",
      "language": "cs-CZ",
      "files": [
        "/workspace/source/locales/cs-CZ.json"
      ]
    },
    {
      "code": "de",
      "name": "German",
      "language": "de-DE",
      "files": [
        "/workspace/source/locales/de-DE.json"
      ]
    },
    {
      "code": "hu",
      "name": "Hungarian",
      "language": "hu-HU",
      "files": [
        "/workspace/source/locales/hu-HU.json"
      ]
    },
    {
      "code": "sk",
      "name": "Slovak",
      "language": "sk-SK",
      "files": [
        "/workspace/source/locales/sk-SK.json"
      ]
    },
    {
      "code": "pl",
      "name": "Polish",
      "language": "pl-PL",
      "files": [
        "/workspace/source/locales/pl-PL.json"
      ]
    },
    {
      "code": "ro",
      "name": "Romanian",
      "language": "ro-RO",
      "files": [
        "/workspace/source/locales/ro-RO.json"
      ]
    },
    {
      "code": "uk",
      "name": "Ukrainian",
      "language": "uk-UA",
      "files": [
        "/workspace/source/locales/uk-UA.json"
      ]
    },
    {
      "code": "es",
      "name": "Spanish",
      "language": "es-ES",
      "files": [
        "/workspace/source/locales/es-ES.json"
      ]
    },
    {
      "code": "fr",
      "name": "French",
      "language": "fr-FR",
      "files": [
        "/workspace/source/locales/fr-FR.json"
      ]
    },
    {
      "code": "pt",
      "name": "Portuguese",
      "language": "pt-PT",
      "files": [
        "/workspace/source/locales/pt-PT.json"
      ]
    },
    {
      "code": "ru",
      "name": "Russian",
      "language": "ru-RU",
      "files": [
        "/workspace/source/locales/ru-RU.json"
      ]
    },
    {
      "code": "sv",
      "name": "Swedish",
      "language": "sv-SE",
      "files": [
        "/workspace/source/locales/sv-SE.json"
      ]
    },
    {
      "code": "el",
      "name": "Greek",
      "language": "el-GR",
      "files": [
        "/workspace/source/locales/el-GR.json"
      ]
    },
    {
      "code": "it",
      "name": "Italian",
      "language": "it-IT",
      "files": [
        "/workspace/source/locales/it-IT.json"
      ]
    },
    {
      "code": "bg",
      "name": "Bulgarian",
      "language": "bg-BG",
      "files": [
        "/workspace/source/locales/bg-BG.json"
      ]
    },
    {
      "code": "si",
      "name": "Slovenian",
      "language": "sl-SI",
      "files": [
        "/workspace/source/locales/sl-SI.json"
      ]
    },
    {
      "code": "hr",
      "name": "Croatian",
      "language": "hr-HR",
      "files": [
        "/workspace/source/locales/hr-HR.json"
      ]
    },
    {
      "code": "lv",
      "name": "Latvian",
      "language": "lv-LV",
      "files": [
        "/workspace/source/locales/lv-LV.json"
      ]
    },
    {
      "code": "lt",
      "name": "Lithuanian",
      "language": "lt-LT",
      "files": [
        "/workspace/source/locales/lt-LT.json"
      ]
    },
    {
      "code": "et",
      "name": "Estonian",
      "language": "et-EE",
      "files": [
        "/workspace/source/locales/et-EE.json"
      ]
    },
    {
      "code": "da",
      "name": "Danish",
      "language": "da-DK",
      "files": [
        "/workspace/source/locales/da-DK.json"
      ]
    },
    {
      "code": "fi",
      "name": "Finnish",
      "language": "fi-FI",
      "files": [
        "/workspace/source/locales/fi-FI.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_and_default",
  "lazy": false,
  "langDir": "locales/",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "English",
    "language": "en-GB",
    "files": [
      {
        "path": "/workspace/source/locales/en-GB.json"
      }
    ]
  },
  {
    "code": "cs",
    "name": "Czech",
    "language": "cs-CZ",
    "files": [
      {
        "path": "/workspace/source/locales/cs-CZ.json"
      }
    ]
  },
  {
    "code": "de",
    "name": "German",
    "language": "de-DE",
    "files": [
      {
        "path": "/workspace/source/locales/de-DE.json"
      }
    ]
  },
  {
    "code": "hu",
    "name": "Hungarian",
    "language": "hu-HU",
    "files": [
      {
        "path": "/workspace/source/locales/hu-HU.json"
      }
    ]
  },
  {
    "code": "sk",
    "name": "Slovak",
    "language": "sk-SK",
    "files": [
      {
        "path": "/workspace/source/locales/sk-SK.json"
      }
    ]
  },
  {
    "code": "pl",
    "name": "Polish",
    "language": "pl-PL",
    "files": [
      {
        "path": "/workspace/source/locales/pl-PL.json"
      }
    ]
  },
  {
    "code": "ro",
    "name": "Romanian",
    "language": "ro-RO",
    "files": [
      {
        "path": "/workspace/source/locales/ro-RO.json"
      }
    ]
  },
  {
    "code": "uk",
    "name": "Ukrainian",
    "language": "uk-UA",
    "files": [
      {
        "path": "/workspace/source/locales/uk-UA.json"
      }
    ]
  },
  {
    "code": "es",
    "name": "Spanish",
    "language": "es-ES",
    "files": [
      {
        "path": "/workspace/source/locales/es-ES.json"
      }
    ]
  },
  {
    "code": "fr",
    "name": "French",
    "language": "fr-FR",
    "files": [
      {
        "path": "/workspace/source/locales/fr-FR.json"
      }
    ]
  },
  {
    "code": "pt",
    "name": "Portuguese",
    "language": "pt-PT",
    "files": [
      {
        "path": "/workspace/source/locales/pt-PT.json"
      }
    ]
  },
  {
    "code": "ru",
    "name": "Russian",
    "language": "ru-RU",
    "files": [
      {
        "path": "/workspace/source/locales/ru-RU.json"
      }
    ]
  },
  {
    "code": "sv",
    "name": "Swedish",
    "language": "sv-SE",
    "files": [
      {
        "path": "/workspace/source/locales/sv-SE.json"
      }
    ]
  },
  {
    "code": "el",
    "name": "Greek",
    "language": "el-GR",
    "files": [
      {
        "path": "/workspace/source/locales/el-GR.json"
      }
    ]
  },
  {
    "code": "it",
    "name": "Italian",
    "language": "it-IT",
    "files": [
      {
        "path": "/workspace/source/locales/it-IT.json"
      }
    ]
  },
  {
    "code": "bg",
    "name": "Bulgarian",
    "language": "bg-BG",
    "files": [
      {
        "path": "/workspace/source/locales/bg-BG.json"
      }
    ]
  },
  {
    "code": "si",
    "name": "Slovenian",
    "language": "sl-SI",
    "files": [
      {
        "path": "/workspace/source/locales/sl-SI.json"
      }
    ]
  },
  {
    "code": "hr",
    "name": "Croatian",
    "language": "hr-HR",
    "files": [
      {
        "path": "/workspace/source/locales/hr-HR.json"
      }
    ]
  },
  {
    "code": "lv",
    "name": "Latvian",
    "language": "lv-LV",
    "files": [
      {
        "path": "/workspace/source/locales/lv-LV.json"
      }
    ]
  },
  {
    "code": "lt",
    "name": "Lithuanian",
    "language": "lt-LT",
    "files": [
      {
        "path": "/workspace/source/locales/lt-LT.json"
      }
    ]
  },
  {
    "code": "et",
    "name": "Estonian",
    "language": "et-EE",
    "files": [
      {
        "path": "/workspace/source/locales/et-EE.json"
      }
    ]
  },
  {
    "code": "da",
    "name": "Danish",
    "language": "da-DK",
    "files": [
      {
        "path": "/workspace/source/locales/da-DK.json"
      }
    ]
  },
  {
    "code": "fi",
    "name": "Finnish",
    "language": "fi-FI",
    "files": [
      {
        "path": "/workspace/source/locales/fi-FI.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
