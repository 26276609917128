import { ApiCacheCodes, Environments } from '../../common/enums'
import type { CacheOptions } from 'nitropack'

const cacheMaxAge = process.env.NUXT_PUBLIC_ENVIRONMENT === Environments.PROD ? 60 : 0

const cacheOpts = (name: string): CacheOptions => ({
  maxAge: cacheMaxAge, // Cache duration in seconds
  name, // Optional name for the cache
  getKey: (id: string, language: string) => `${name}-${id}-${language}`, // Unique cache key
})

const clearExpiredCache = async (cacheId: string) => {
  const storage = useStorage('cache')
  const cacheKey = `nitro:functions:${ApiCacheCodes.TRACKING_DATA}:${ApiCacheCodes.TRACKING_DATA}-${cacheId}.json`

  try {
    // Get cache item metadata
    const meta = await storage.getMeta(cacheKey)

    if (meta) {
      const maxAge = cacheMaxAge * 60 * 1000 // 1 hour in milliseconds
      const now = Date.now()
      const mtime = new Date(meta.mtime).getTime()

      // Check if the cache item has expired
      if (now - mtime > maxAge) {
        console.log(`Cache expired: ${cacheKey}, removing...`)
        await storage.removeItem(cacheKey)
      } else {
        console.log(`Cache is still valid: ${cacheKey}`)
      }
    } else {
      console.log(`No metadata found for cache key: ${cacheKey}`)
    }
  } catch (error) {
    console.error(`Error clearing cache for key: ${cacheKey}`, error)
  }
}

export { cacheOpts, clearExpiredCache }
